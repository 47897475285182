<template>
<v-row class="ma-0 py-5">
  <v-col cols="12" md="10" lg="8" class="mx-auto">
    <v-card elevation="0">
      <v-col>
        <v-card-title><v-icon>mdi-car-brake-hold</v-icon> Heritage</v-card-title>
        <v-divider/>
        <v-row>
          <v-col cols="12" sm="7" v-if="countHeritage == 0 && !runRandom">
            <v-card-text>
              Bienvenue <b>{{ $store.state.auth.user.name }}</b> !<br>
              Avant de commencer à jouer, voyons de quoi va hériter votre personnage virtuel !
              <br><br>
              L'héritage d'un perso est composé de 3 éléments : 
              Véhicules · Logement · Entreprise
              <br><br>
              Votre héritage est tiré au sort aléatoirement par le système.<br>
              Vous avez le droit d'effectuer 3 tirages au maximum, si les précédents ne vous conviennent pas.<br>
              Vous pouvez hériter de plus, ou de moins... à chaque fois !<br><br>
              BONNE CHANCE !
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="5" class="text-center mt-5 mx-auto" v-if="!runRandom">
            <v-btn height="120" width="120" color="red" class="mb-1" fab @click="startRandom">
              <v-icon style="font-size:70px;">mdi-car-brake-hold</v-icon>
            </v-btn><br>
            Cliquez pour générer<br>un héritage<br>
            <b>{{ 3 - countHeritage }} tirages restant</b>
          </v-col>
          <v-col cols="12" sm="5" class="text-center mt-5 mx-auto" v-if="runRandom">
            <v-progress-circular :rotate="-90" :size="120" :width="10" style=""
                                :value="loader" :color="(loader < 50) ? 'red' : (loader < 70) ? 'orange' : 'green'">
              <h1>{{ loader }}%</h1>
            </v-progress-circular><br><br>
            Génération d'un nouvel héritage...<br>
            Merci de patienter...
            <!-- <v-btn height="120" width="120" fab @click="stopRandom()">
              <v-icon style="font-size:70px;">mdi-car-brake-hold</v-icon>
            </v-btn><br><br>
            Stop -->
          </v-col>
        </v-row>
      </v-col>
    </v-card>


    <v-card elevation="0" color="transparent" class="mt-5" min-height="400" v-if="randomHeritage != null && !runRandom">
      <v-row class="ma-0 pt-3">
        
        <v-col cols="12" class="text-center">
          <h2>Contenu de votre héritage ...</h2>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6"  class="text-center">
          <template v-for="(company, x) in randomHeritage.companies">
            <v-card outlined color="" elevation="0" :key="x" class="pa-5 pt-3">
              <v-gravatar height="60" style="border-radius:50%; margin-bottom:-15px;"
                    :default-img="'retro'" :email="company.name" class="mr-2"/>
              <h2 class="mt-2">{{ company.name }}</h2>
              <v-card-subtitle class="pt-0 pb-0">Valeur : 
                {{ company.surfacePrice * company.surface }} òto ~{{ monyToEuro(company.surfacePrice * company.surface) }}€
              </v-card-subtitle>
            </v-card>
          </template>
          <template v-if="randomHeritage.companies.length == 0">
            <v-card outlined color="" elevation="0" class="pa-5 pt-3" height="120">
              
              <v-icon class="mt-2" large>mdi-cancel</v-icon>
              <h2>Aucune entreprise</h2>
            </v-card>
          </template>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6" class="text-center mt-5">
          <template v-for="(product, x) in randomHeritage.vehicles">
            <div class="px-3 d-inline-block" :key="x">
              <v-progress-circular :rotate="-90" :size="80" :width="10"
                :value="product.kmToday / product.kmByDay * 100" 
                :color="(product.kmToday / product.kmByDay * 100 < 50) ? 'green' : (product.kmToday / product.kmByDay * 100 < 70) ? 'orange' : 'red'"
                :title="'utilisation aujourd\'hui ' + product.kmToday / product.kmByDay * 100 + '%'"
              >
                
                  <v-icon large>mdi-{{ product.icon }}</v-icon>
              </v-progress-circular><br>
              {{ product.stock }} {{ product.name }}{{ product.stock > 1 ? 's' : '' }}
            </div>
          </template>
        </v-col>

        <v-row class="mx-0" v-if="randomHeritage.houses.length > 0">
          <template v-for="(house, x) in randomHeritage.houses">
            <v-col cols="12" sm="6" md="6" lg="6" :key="x+'house'" class="text-center">
              <HouseCard v-if="house!=null" :house="house" :small="true"/>
            </v-col>
          </template>
        </v-row>
        <v-row class="mx-0" v-else>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-card class="text-center pa-5" outlined elevation="0">
              <v-icon class="mt-2" large>mdi-cancel</v-icon>
              <h2>Aucun logement</h2>
            </v-card>
          </v-col>
        </v-row>

      </v-row>
    </v-card>

    <v-card elevation="0" color="transparent" class="mt-5" v-if="randomHeritage && !runRandom">
      <v-divider/>
      <v-card-text>
        <h2 class="text-center">Résultat du tirage</h2><br>
        -
        <template v-if="randomHeritage.companies.length == 0">
          <b>Vous n'héritez d'aucune entreprise</b>, mais pas de panique !<br>
          Vous pourrez trouver un emplois salarié, ou créer votre propre entreprise plus tard dans le jeu...
        </template>
        <template v-else>
          <b>Vous devenez l'heureux propriétaire {{ randomHeritage.companies.length == 1 ? "d'une" : "de" }} 
                       {{ randomHeritage.companies.length > 1 ? randomHeritage.companies.length : '' }} 
                       entreprise{{ randomHeritage.companies.length > 1 ? 's' : '' }} !
          </b>
          <br>Si celle-ci ne vous intéresse pas, vous pouvez la revendre à un autre joueur !
        </template>
        <br><br>
        -
        <template v-if="randomHeritage.houses.length == 0">
          <b>Vous n'héritez d'aucun logement</b>, mais vous aurez la possibilité d'en louer ou d'en acheter un plus tard.
        </template>
        <template v-else>
          <b>Vous héritez <template v-if="randomHeritage.companies.length > 0">aussi</template>
          {{ randomHeritage.houses.length == 1 ? "d'un" : "de" }} 
          {{ randomHeritage.houses.length > 1 ? randomHeritage.houses.length : '' }} 
          logement{{ randomHeritage.houses.length > 1 ? 's' : '' }}</b>,
          d'une valeur totale de {{ totalHouses }} òto !<small> Soit environ ~ {{ priceHouseEuro }}€</small>.
          <template v-if="randomHeritage.houses.length > 0">
            <br>Si vous en avez plusieurs, vous pouvez louer vos logements aux autres joueurs, en échange d'un loyer.
            <br>Vous pouvez aussi les revendre !
          </template>
        </template>


        <br><br>
        -
        <template v-if="randomHeritage.vehicles.length == 0">
          Vous n'héritez d'aucun véhicule, mais vous aurez la possibilité d'en acheter un plus tard.
        </template>
        <template v-else>
          <b>Vous héritez {{ randomHeritage.vehicles.length == 1 ? "d'un" : "de plusieurs" }}
          véhicule{{ randomHeritage.vehicles.length > 1 ? 's' : '' }}</b> pour vous déplacer et transporter des marchandises : 
          <template v-for="(product, x) in randomHeritage.vehicles">
            <br :key="x">
            <span class="ml-5" :key="'s'+x"><b>· {{ product.stock }} {{ product.name }}{{ product.stock > 1 ? 's' : '' }}</b>
            qui peut transporter {{ product.weightCapacity }}kg sur {{ product.kmByDay }} km/jour
            </span>
          </template>
        </template>
      </v-card-text>
    </v-card>

    <v-card color="transparent" elevation="0" class="text-right mt-5" v-if="randomHeritage && !runRandom">
      <v-divider/>
      <v-card-text class="blue--text pb-0" v-if="countHeritage < 3">
        Si vous n'êtes pas satisfait par ce tirage, vous pouvez encore tenter votre chance <b>{{ 3 - countHeritage }}</b> fois !
      </v-card-text>
      <v-card-text class="green--text pt-0" v-if="countHeritage < 3">
        Sinon : validez cet héritage, et passez à la suite !
      </v-card-text>
      <v-card-text class="orange--text" v-if="countHeritage >= 3">
        Vous ne pouvez plus générer d'autres héritages !<br>
        Votre destin est scellé !
      </v-card-text>
      
      <v-btn large rounded class="ml-2 mb-2" color="blue" @click="startRandom()">
        <v-icon class="mr-1">mdi-car-brake-hold</v-icon>
        Générer un nouvel héritage
      </v-btn>
      <v-btn large rounded class="ml-2 mb-2" color="green" @click="saveHeritage()">
        <v-icon>mdi-check</v-icon>
        Valider cet héritage
      </v-btn>
    </v-card>
  </v-col>
</v-row>
</template>

<style></style>

<script>

import axios from 'axios'
import router from '../router/router'
//import core from '../plugins/core.js'

import HouseCard from '@/components/entity/HouseCard.vue'

import Gravatar from 'vue-gravatar'

const config = require('../config/' + process.env.NODE_ENV)

export default {
  name: 'heritage',
  components: { HouseCard, 'v-gravatar' : Gravatar },
  data: () => ({
    runRandom: false,
    randomHeritage: null,
    loader: 0,
    countHeritage: 0
  }),
  async mounted(){
    if(this.$store.state.auth.user.countRandomHeritage >= 3) router.push('/map')
    //this.getRandomHeritage()
  },
  methods: {
    startRandom(){
      this.loader = 0
      this.runRandom = true
      if(this.runner == null)
      this.runner = setInterval(()=>{
        if(this.runRandom) this.randomize()
        else this.stopRandom()
      }, 300)
    },
    stopRandom(){
      this.getRandomHeritage()
      clearInterval(this.runner)
      //this.runRandom = false
      this.runner = null
    },
    randomize(){
      this.loader += 46
      if(this.loader >= 100) this.stopRandom()
    },
    async getRandomHeritage(){
      let res = await axios.get("/user/random-heritage")
      if(!res.data.error){
        this.runRandom = false
        this.randomHeritage = res.data.heritage
        this.countHeritage = res.data.heritage.count
      }
      clearInterval(this.runner)
    },
    async saveHeritage(){
      axios.post("/user/save-heritage")
            .then((res)=>{
              if(!res.data.error){
                this.$store.dispatch('auth/refreshUserData')
                this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Votre héritage a bien été enregistré !",
                                  text: '',
                                  icon: 'thumb-up' })
                this.$router.push('/map')
              }
            })
      
    },
    monyToEuro(amount){ 
      let price = parseInt(amount * config.monyToEuro).toString()
      if(price.length > 3) price = price.substr(0, price.length-3) + " " + price.substr(price.length-3, 3)
      return price
    },

  },
  computed: {
    totalHouses(){
      let total = 0
      this.randomHeritage.houses.forEach((house)=>{
        total += house.price
      })
      console.log("total", total)
      return total.toFixed(2)
    },
    priceHouseEuro(){
      return this.monyToEuro(this.totalHouses)
      //  let price = parseInt(this.monyToEuro(this.totalHouses)) + ""
      //  console.log("price.length ", price.length )
      //  if(price.length > 3) price = price.substr(0, price.length-3) + " " + price.substr(price.length-3, 3)
      //  return price
     }
    
  }
}
</script>
